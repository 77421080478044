import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import Input from 'components/Input';
import FormContainer from 'components/LoanForm/FormContainer';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import { ReferralProgramResult } from 'enums/FlowNextResults';
import { copyToClipboard } from 'utils/copyToClipboard';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { useLocation } from 'react-router-dom';
import useReferralProgram from 'components/CardFlow/ReferralProgram/useReferralProgram';
import { ReferralProgramID } from 'handlers/cardData';
import { ProfessionGroup, getPluralGroupTerm } from 'enums/ProfessionGroup';
import { getProfessionGroup } from 'selectors/professionGroup';
import { ConversionType, trackConversion } from 'utils/analytics';

import { ReactComponent as Schedule } from 'images/schedule.svg';

import ShareButtons, { sharePlatforms } from './ShareButtons/ShareButtons';
import CustomizeMessage from './CustomizeMessage/CustomizeMessage';
import GalesPreview from './GalesPreview';
import { REFERRAL_PROGRAM_DISPLAY_DATA } from './referralPrograms';
import AreYouSureModal from './AreYouSureModal';

import styles from './ReferralProgram.module.scss';

const getDefaultText = (professionGroup: ProfessionGroup) =>
  `I just signed up for Plannery, a new card for ${getPluralGroupTerm(professionGroup)}. 🤑👩🏽‍⚕️🩺👀`;

const ReferralProgram = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const state = useLocation().state as { fromYourFinances?: boolean; fromPaycheckSwitch?: boolean };

  const [selectedPlatform, setSelectedPlatform] = useState<number>(sharePlatforms[0].id);
  const { professionGroup } = useSelector(getProfessionGroup);
  const [shareMessage, setShareMessage] = useState<string>(getDefaultText(professionGroup));
  const [areYouSureModal, setAreYouSureModal] = useState<boolean>(false);
  const [sharedOnce, setSharedOnce] = useState<boolean>(false);

  const { referralLink, referralProgram } = useSelector(getCardData);
  const { programDisplayData: variant } = useReferralProgram();
  const { id: programId, icon, title, subtitle } = REFERRAL_PROGRAM_DISPLAY_DATA[variant.id]({
    professionGroup,
    title: state?.fromPaycheckSwitch ? 'Thanks for your interest!' : undefined,
    icon: state?.fromPaycheckSwitch ? <Schedule /> : undefined,
  });
  const { isFinancialCheckupFlow } = useCurrentFlow();

  const buttonLabel = state?.fromYourFinances ? 'Share' : 'Next';
  const secondaryButtonLabel = sharedOnce ? 'Done' : 'Not Now';

  const handleCopyLink = () => {
    copyToClipboard(referralLink!);
    analytics.track('Referral Program - Copy Link Button Clicked');
    trackConversion(ConversionType.SocialShareInitiated);
  };

  const handleNextButton = () => {
    const { stepsToComplete } = getPlatform(selectedPlatform);
    if (stepsToComplete) {
      copyToClipboard(shareMessage);
    }
    analytics.track('Referral Program - Next Button Clicked', {
      platform: selectedPlatform,
    });
    trackConversion(ConversionType.SocialShareInitiated);

    if (!state?.fromYourFinances) {
      handleNext(ReferralProgramResult.NextSteps);
    } else {
      setSharedOnce(true);
    }
  };

  const handleNotNow = () => {
    analytics.track('Referral Program - Not Now Button Clicked');
    if (!state?.fromYourFinances) {
      setAreYouSureModal(true);
    } else {
      handleNext(ReferralProgramResult.NotNow);
    }
  };

  const handleModalDecline = () => {
    if (state?.fromYourFinances) {
      handleNext(ReferralProgramResult.NotNow);
    } else {
      handleNext(ReferralProgramResult.NextSteps);
    }
  };

  const { button: PlatformButton, stepsToComplete } = getPlatform(selectedPlatform);

  useEffect(() => {
    analytics.track('Referral Program Viewed', {
      programID: programId,
      credits: referralProgram?.credits,
      numReferrals: referralProgram?.referredApplicants.length,
    });
  }, []);

  return (
    <>
      <FormNavigation {...navigationInfo} showBackLink={isFinancialCheckupFlow} />
      <FormContainer icon={icon} title={title} subtitle={subtitle}>
        {programId === ReferralProgramID.Gales && <GalesPreview />}

        <CustomizeMessage shareMessage={shareMessage} setShareMessage={setShareMessage} />

        <ShareButtons selected={selectedPlatform} setSelectedPlatform={setSelectedPlatform} />

        <div className={styles.shareLinkContainer}>
          <Input className={styles.input} readOnly value={referralLink ?? 'https://planneryapp.com'} />
          <p onClick={handleCopyLink} className={styles.copyLinkButton}>
            Copy Link
          </p>
        </div>

        {stepsToComplete && <div className={styles.stepsToComplete}>{stepsToComplete}</div>}

        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <PlatformButton
              beforeOnClick={handleNextButton}
              title={shareMessage}
              quote={shareMessage}
              body={shareMessage}
              separator=" - "
              hashtag="plannery"
              url={referralLink ?? 'https://planneryapp.com'}
              appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
            >
              {buttonLabel}
            </PlatformButton>
          </div>

          <Button type={ButtonType.Inverted} onClick={handleNotNow}>
            {secondaryButtonLabel}
          </Button>
        </div>
      </FormContainer>

      {areYouSureModal && (
        <AreYouSureModal handleDecline={handleModalDecline} closeModal={() => setAreYouSureModal(false)} />
      )}
    </>
  );
};

export default ReferralProgram;

const getPlatform = (platformId: number) => sharePlatforms.find((platform) => platform.id === platformId)!;
