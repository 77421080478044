import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { UpdatedEmploymentData } from 'api/ApplicationDataApi';
import { PayrollProviderName } from 'api/EmployersApi';

import Anchor from 'components/Common/Buttons/Anchor';
import Button from 'components/Button';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import DatePicker from 'components/DatePicker';
import Loader from 'components/Loader';
import FormContainer from 'components/LoanForm/FormContainer';
import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import { InputSelectOption } from 'components/InputSelect/InputSelect';
import NumberInput from 'components/NumberInput';
import { StepComponent } from 'components/Steps/stepUtils';

import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { EmploymentDetailsResult } from 'enums/FlowNextResults';
import { usePartnerData } from 'enums/PartnerName';

import { clearPrimaryEmployer, clearSecondaryEmployer, initialState, setYourEmployerData } from 'handlers/yourEmployer';
import { PayFrequencyOptionLabel, PayFrequencyOptionValue } from 'handlers/yourTotalIncome';

import useCurrentFlow from 'hooks/useCurrentFlow';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { getApplicationData as applicationData } from 'selectors/getApplicationData';
import { getYourEmployer } from 'selectors/yourEmployer';

import { getApplicationData, updateEmploymentData } from 'thunks';

import { getMessageForRequiredFields } from 'utils/errors';
import { getYearsFromNow } from 'utils/dateUtils';
import { STATE_OPTIONS } from 'utils/getCountryStateLabel';

import styles from './EmploymentDetails.module.scss';

enum YourEmployerInputLabel {
  EmployerName = 'employer name',
  HireDate = 'Start of employment',
  JobTitle = 'Your job title',
  AnnualIncome = 'Gross annual income',
  PayFrequency = 'Pay Frequency',
  AdditionalIncome = 'Additional income',
  IsLicensedProfessional = 'I am a licensed healthcare professional',
  HealthcareLicenseNumber = 'Healthcare license number',
  HealthcareLicenseState = 'Healthcare license state',
}

export const PAY_FREQUENCY_OPTIONS = [
  { label: PayFrequencyOptionLabel.Weekly, value: PayFrequencyOptionValue.Weekly },
  { label: PayFrequencyOptionLabel.BiWeekly, value: PayFrequencyOptionValue.BiWeekly },
  { label: PayFrequencyOptionLabel.SemiMonthly, value: PayFrequencyOptionValue.SemiMonthly },
  { label: PayFrequencyOptionLabel.Monthly, value: PayFrequencyOptionValue.Monthly },
  { label: PayFrequencyOptionLabel.Other, value: PayFrequencyOptionValue.Other },
] as InputSelectOption[];

export const ANNUAL_INCOME_MAX_LENGTH = 14;
export const MONETARY_PREFIX = '$';

const EMPLOYER_NUMBER = Object.freeze(['Primary', 'Secondary']);

const EmploymentDetails = ({ handleNext }: StepComponent) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application, isLoading: isApplicationLoading } = useSelector(applicationData);
  const {
    id: applicationId,
    totalAnnualIncome,
    notEmployed,
    verificationStepProgress,
    employment: {
      employer1: { annualIncome: currentAnnualIncome, payFrequency: currentPayFrequency },
    },
  } = application!;

  const { isFinancialCheckupFlow } = useCurrentFlow();

  const { partnerName } = usePartnerData();

  const {
    isLicensedProfessional,
    isLoading,
    additionalIncome,
    employer1,
    employer2,
    healthcareLicenseNumber,
    healthcareLicenseState,
  } = useSelector(getYourEmployer);

  const [primaryEmployerEnabled, setPrimaryEmployerEnabled] = useState(!notEmployed);
  const [secondaryEmployerEnabled, setSecondaryEmployerEnabled] = useState(!!employer2.employerName);
  const [additionalIncomeEnabled, setAdditionalIncomeEnabled] = useState(!!additionalIncome);

  const { annualIncome, ...employer1Rest } = employer1;

  const {
    formState: { isValid, errors },
    trigger,
    register,
    unregister,
    watch,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      employer1: { annualIncome: annualIncome ?? totalAnnualIncome, ...employer1Rest },
      employer2: { ...employer2 },
      isLicensedProfessional,
      additionalIncome,
      healthcareLicenseNumber,
      healthcareLicenseState,
    },
  });

  const watcher = watch();

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value.trim());
    trigger(event.target.name);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name, event.target.value);
    trigger(event.target.name);
  };

  useEffect(() => {
    if (primaryEmployerEnabled) {
      register(`employer1.employerName`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
      });
      register(`employer1.jobTitle`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
      });
      register(`employer1.annualIncome`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
      });
      if (!currentPayFrequency) {
        register(`employer1.payFrequency`, {
          required: getMessageForRequiredFields(YourEmployerInputLabel.PayFrequency),
        });
      }
    }

    register('additionalIncome', {
      required: false,
    });

    if (partnerName === undefined) {
      register(`isLicensedProfessional`, {
        required: false,
      });

      register('healthcareLicenseNumber', {
        required: getMessageForRequiredFields(YourEmployerInputLabel.HealthcareLicenseNumber),
      });

      register('healthcareLicenseState', {
        required: getMessageForRequiredFields(YourEmployerInputLabel.HealthcareLicenseState),
      });
    }

    if (secondaryEmployerEnabled) {
      register(`employer2.employerName`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.EmployerName),
      });
      register(`employer2.jobTitle`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.JobTitle),
      });
      register(`employer2.annualIncome`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.AnnualIncome),
      });
      register(`employer2.hireDate`, {
        required: getMessageForRequiredFields(YourEmployerInputLabel.HireDate),
      });
    } else {
      unregister(`employer2.employerName`);
      unregister(`employer2.jobTitle`);
      unregister(`employer2.annualIncome`);
      unregister(`employer2.hireDate`);
    }
  }, [watcher, register, primaryEmployerEnabled, secondaryEmployerEnabled, isFinancialCheckupFlow, partnerName]);

  useEffect(() => {
    const refetchApplicationData = async () => {
      await dispatch(getApplicationData(application!.id));
    };
    // If the employer is using Argyle, the system could have updated their income.
    // Therefore, we need to refetch the application data  to ensure we have the most up to date information.
    if (employer1.payrollProvider === PayrollProviderName.Argyle && verificationStepProgress?.paystubs) {
      refetchApplicationData();
    }
  }, []);

  useEffect(() => {
    // If the application was updated, we need to update the annual income to the current value.
    if (
      employer1.payrollProvider === PayrollProviderName.Argyle &&
      verificationStepProgress?.paystubs &&
      currentAnnualIncome !== watcher.employer1.annualIncome
    ) {
      setValue('employer1.annualIncome', currentAnnualIncome);
    }
  }, [application]);

  const handleContinue = async () => {
    dispatch(setYourEmployerData(watcher));
    const employmentData: UpdatedEmploymentData = {
      applicationId,
      employmentData: {
        employment: [],
        isLicensedProfessional: watcher.isLicensedProfessional,
        healthcareLicenseNumber: watcher.healthcareLicenseNumber,
        healthcareLicenseState: watcher.healthcareLicenseState,
        additionalIncome:
          watcher.additionalIncome !== undefined
            ? Number(`${watcher.additionalIncome}`.replace(/[^0-9.-]+/g, ''))
            : undefined,
      },
    };

    if (primaryEmployerEnabled) {
      employmentData.employmentData.employment.push({
        employerName: watcher.employer1.employerName,
        jobTitle: watcher.employer1.jobTitle,
        annualIncome:
          watcher.employer1.annualIncome !== undefined
            ? Number(`${watcher.employer1.annualIncome}`.replace(/[^0-9.-]+/g, ''))
            : undefined,
        payFrequency: watcher.employer1.payFrequency,
      });
    } else {
      employmentData.employmentData.employment.push({});
    }

    if (secondaryEmployerEnabled) {
      employmentData.employmentData.employment.push({
        employerName: watcher.employer2?.employerName,
        jobTitle: watcher.employer2?.jobTitle,
        annualIncome:
          watcher.employer2?.annualIncome !== undefined
            ? Number(`${watcher.employer2.annualIncome}`.replace(/[^0-9.-]+/g, ''))
            : undefined,
        hireDate: watcher.employer2?.hireDate,
      });
    } else {
      employmentData.employmentData.employment.push({});
    }

    const updatedApplicationData = await dispatchWithUnwrap(updateEmploymentData(employmentData));

    analytics.track('Employment Details Submitted');
    if (updatedApplicationData?.application.status !== ApplicationStatusName.Rejected) {
      handleNext(EmploymentDetailsResult.Continue);
    } else {
      handleNext(EmploymentDetailsResult.Rejected);
    }
  };

  const handleAddEmployer = () => {
    if (primaryEmployerEnabled) {
      setSecondaryEmployerEnabled(true);
    } else {
      setPrimaryEmployerEnabled(true);
    }
  };

  const handleDeleteEmployer = () => {
    if (secondaryEmployerEnabled) {
      setSecondaryEmployerEnabled(false);
      dispatch(clearSecondaryEmployer());
      reset({
        ...watcher,
        employer2: initialState.employer2,
      });
    } else {
      setPrimaryEmployerEnabled(false);
      dispatch(clearPrimaryEmployer());
      reset({
        ...watcher,
        employer1: initialState.employer1,
      });
    }
  };

  const handleAddAdditionalIncome = () => {
    setAdditionalIncomeEnabled(true);
  };

  const renderEmployerInputs = (employerNumber: 1 | 2) => {
    const key = `employer${employerNumber}` as 'employer1' | 'employer2';
    const employer = employerNumber === 1 ? employer1 : employer2;
    return (
      <div className={styles.inputs}>
        <Input
          label={`${EMPLOYER_NUMBER[employerNumber - 1]} ${YourEmployerInputLabel.EmployerName}`}
          placeholder="Employer name"
          errorMessage={errors?.[key]?.employerName?.message}
          className={styles.formInput}
          name={`${key}.employerName`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[key]?.employerName}
          autoComplete="organization"
          disabled={employer?.verifiedName}
        />
        {employerNumber === 2 && (
          <DatePicker
            maxDate={getYearsFromNow(0)}
            minDate={getYearsFromNow(-30)}
            placeHolder="MM/DD/YYYY"
            label="Start of employment"
            className={styles.formInput}
            selected={watcher.employer2?.hireDate ? new Date(watcher.employer2?.hireDate) : null}
            onChange={(date) => {
              setValue(`${key}.hireDate`, date);
              trigger(`${key}.hireDate`);
            }}
            errorMessage={errors?.[key]?.hireDate?.message}
            name={`${key}.hireDate`}
            disabled={employer?.verifiedHireDate}
          />
        )}
        <Input
          label={YourEmployerInputLabel.JobTitle}
          placeholder="Job title"
          errorMessage={errors?.[key]?.jobTitle?.message}
          className={styles.formInput}
          name={`${key}.jobTitle`}
          onBlur={onBlur}
          onChange={onChange}
          value={watcher[key]?.jobTitle}
          autoComplete="organization-title"
        />
        <NumberInput
          label={YourEmployerInputLabel.AnnualIncome}
          prefix="$"
          placeholder="$0"
          errorMessage={errors?.[key]?.annualIncome?.message}
          thousandSeparator
          className={styles.formInput}
          name={`${key}.annualIncome`}
          onBlur={onBlur}
          onChange={onChange}
          value={`$${watcher[key]?.annualIncome}`}
          maxLength={ANNUAL_INCOME_MAX_LENGTH}
          dataNeuroLabel={`income--${key}`}
          disabled={employer?.verifiedAnnualIncome}
        />
        {employerNumber === 1 && !currentPayFrequency && (
          <InputSelect
            label={YourEmployerInputLabel.PayFrequency}
            options={PAY_FREQUENCY_OPTIONS}
            onChange={(option) => {
              const eventName = `${key}.payFrequency`;
              setValue(eventName, option.value);
              trigger(eventName);
            }}
            placeholder="Pay Frequency"
            className={styles.formInput}
            name={`${key}.payFrequency`}
            value={watcher[key]?.payFrequency}
          />
        )}
      </div>
    );
  };

  if (isApplicationLoading) {
    return (
      <FormContainer title="Employment Details" subtitle="Where do you work?">
        <Loader color="#9d86f9" size={80} className={styles.loader} />
      </FormContainer>
    );
  }

  return (
    <FormContainer title="Employment Details" subtitle="Where do you work?">
      {primaryEmployerEnabled && (
        <>
          {renderEmployerInputs(1)}
          {!secondaryEmployerEnabled && application?.notEmployed && (
            <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleDeleteEmployer}>
              <div className={styles.cross}>+</div>Delete primary employer
            </Anchor>
          )}
        </>
      )}

      {!primaryEmployerEnabled && (
        <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleAddEmployer}>
          + Add employer
        </Anchor>
      )}
      {primaryEmployerEnabled && !secondaryEmployerEnabled && (
        <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleAddEmployer}>
          + Add another employer
        </Anchor>
      )}

      {secondaryEmployerEnabled && (
        <>
          <div className={styles.divider} />
          {renderEmployerInputs(2)}
          <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleDeleteEmployer}>
            <div className={styles.cross}>+</div>Delete second employer
          </Anchor>
        </>
      )}

      {!additionalIncomeEnabled && (
        <Anchor className={styles.secondEmployerButton} isLoading={false} onClick={handleAddAdditionalIncome}>
          + Add additional income
        </Anchor>
      )}

      {additionalIncomeEnabled && (
        <>
          <div className={styles.divider} />
          <div className={styles.inputs}>
            <NumberInput
              label={YourEmployerInputLabel.AdditionalIncome}
              prefix="$"
              placeholder="$0"
              thousandSeparator
              className={styles.formInput}
              name="additionalIncome"
              onBlur={onBlur}
              onChange={onChange}
              value={`$${watcher.additionalIncome}`}
              maxLength={ANNUAL_INCOME_MAX_LENGTH}
            />
            <p className={styles.additionalIncomeText}>
              Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
              considered as a basis for repaying this obligation.
            </p>
          </div>
        </>
      )}

      {partnerName === undefined && (
        <>
          <div className={styles.divider} />

          <CheckboxSmall
            label={YourEmployerInputLabel.IsLicensedProfessional}
            className={styles.checkbox}
            checked={watcher.isLicensedProfessional}
            onChange={() => {
              setValue('isLicensedProfessional', !watcher.isLicensedProfessional);
            }}
          />

          <div className={styles.inputs}>
            <Input
              label={YourEmployerInputLabel.HealthcareLicenseNumber}
              placeholder={YourEmployerInputLabel.HealthcareLicenseNumber}
              errorMessage={errors?.healthcareLicenseNumber?.message}
              className={styles.formInput}
              name="healthcareLicenseNumber"
              onBlur={onBlur}
              onChange={(event: React.FocusEvent<HTMLInputElement>) => {
                setValue('isLicensedProfessional', true);
                trigger('isLicensedProfessional');
                onChange(event);
              }}
              value={watcher.healthcareLicenseNumber}
            />

            <InputSelect
              label={YourEmployerInputLabel.HealthcareLicenseState}
              options={STATE_OPTIONS}
              onChange={(option) => {
                setValue('healthcareLicenseState', option.value);
                trigger('healthcareLicenseState');
              }}
              value={watcher.healthcareLicenseState}
              placeholder="State"
              className={styles.formInput}
              name="healthcareLicenseState"
            />
          </div>
        </>
      )}

      <Button isLoading={isLoading} disabled={!isValid} onClick={handleContinue}>
        Save and Continue
      </Button>
    </FormContainer>
  );
};

export default EmploymentDetails;
